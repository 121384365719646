<template>
  <div class="visitor-container" :class="{ loading: loading, 'no-header': pastHeader, debug: devMode }">
    <!-- Header Location-->
    <keep-alive v-if="location.data.header">
      <component :is="location.data.header.component" :location="location.data.header" v-on="$listeners"></component>
    </keep-alive>

    <!-- Header Blocks -->
    <template v-if="location.data.header">
      <template v-for="childBlock in location.data.header.blocks">
        <keep-alive :key="'header-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <scrollTrigger handle="headerBottom" @inView="scrollTriggerHeader" />

    <!-- NavBar Location-->
    <keep-alive v-if="location.data.navBar">
      <component :is="location.data.navBar.component" :location="location.data.navBar" v-on="$listeners"></component>
    </keep-alive>

    <!-- NavBar Blocks -->
    <template v-if="location.data.navBar">
      <template v-for="childBlock in location.data.navBar.blocks">
        <keep-alive :key="'navBar-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>

    <section class="page" :class="location.properties.class">
      <!-- Top Location-->
      <section class="page-top">
        <template v-if="location.data.top">
          <component :is="location.data.top.component" :location="location.data.top" v-on="$listeners"></component>

          <!-- Top Blocks -->
          <template v-for="childBlock in location.data.top.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'top-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </section>

      <!-- Content Location-->
      <section class="page-content">
        <template v-if="location.data.content">
          <component :is="location.data.content.component" :location="location.data.content" v-on="$listeners"></component>

          <!-- Content Blocks -->
          <template v-for="childBlock in location.data.content.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'content-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </section>

      <!-- Bottom Location-->
      <section class="page-bottom">
        <template v-if="location.data.bottom">
          <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

          <!-- Bottom Blocks -->
          <template v-for="childBlock in location.data.bottom.blocks">
            <component :is="childBlock.component" :block="childBlock" :key="'bottom-' + childBlock.id" v-on="$listeners"></component>
          </template>
        </template>
      </section>
    </section>

    <!-- Footer Location-->
    <keep-alive v-if="location.data.footer">
      <component :is="location.data.footer.component" :location="location.data.footer" v-on="$listeners"></component>
    </keep-alive>

    <!-- Footer Blocks -->
    <template v-if="location.data.footer">
      <template v-for="childBlock in location.data.footer.blocks">
        <keep-alive :key="'footer-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </template>
  </div>
</template>

<style>
/* Icons */
@import '../assets/icons.css';

/* Color Overides */
.btn.btn-callout {
  color: white;
  background-color: rgb(6 185 6);
  border-color: rgb(40 148 40);
}

/* Frame */
.visitor.container {
  display: grid;
  height: 100vh;
  grid-template-rows: auto auto 1fr auto;
  gap: 24px;
}
section {
  margin-bottom: 1.5rem;
}
.page-content {
  display: flex;
  justify-content: center;
}
.home .page-content {
  text-align: center;
}
.page-bottom {
  padding: 0 0.8rem;
}

@media (max-width: 840px) {
  section {
    margin-bottom: 0.5rem;
  }
  .page-bottom {
    padding: 0;
  }
}

/* Short Codes */
.callout {
  padding: 1rem;
  border: 5px solid red;
  background: rgb(250, 250, 250);
  margin-bottom: 1.2rem;
}
.callout .headline {
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  margin-bottom: 1rem;
}
.callout .title {
  margin-bottom: 0.5rem;
}
.callout .desc {
  margin-bottom: 0.5rem;
}
.callout .action {
  margin-bottom: 0.5rem;
  text-align: right;
  text-transform: uppercase;
}

/* Divider */
hr.divider {
  box-sizing: inherit;
}

/* Autobacklinks */
.auto-links {
  font-size: xx-small;
}

/* Ad Debug */
.debug .ezoic {
  height: 150px;
  background-color: cadetblue;
}
</style>

<script>
export default {
  name: 'defautPage',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    location: Object,
  },
  methods: {
    scrollTriggerHeader(inView) {
      this.pastHeader = !inView;
    },
  },
  data() {
    return {
      pastHeader: false,
      devMode: process.env.NODE_ENV == 'production' ? false : true,
    };
  },
};
</script>
